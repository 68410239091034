import { NgModule } from '@angular/core';
import { AppHeaderRightButtonDirective, HeaderComponent } from './header.component';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { ModalHeaderComponent } from '../modal-header/modal-header.component';
import { ScheduledCallsIndicatorModule } from '../../scheduled-calls-indicator/scheduled-calls-indicator.module';

@NgModule({
    declarations: [
        HeaderComponent,
        ModalHeaderComponent,
        AppHeaderRightButtonDirective,
    ],
    imports: [
        IonicModule,
        CommonModule,
        ScheduledCallsIndicatorModule,
    ],
    exports: [
        HeaderComponent,
        ModalHeaderComponent,
        AppHeaderRightButtonDirective,
    ]
})
export class HeaderModule {
}
