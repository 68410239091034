import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { RouteGuardService } from './services/auth/route-guard.service';
import { TranslateModule } from '@ngx-translate/core';
import { IonicModule } from '@ionic/angular';
import { HeaderModule } from './components/header/header.module';
import { CommonModule } from '@angular/common';

const routes: Routes = [
    {
        path: '',
        redirectTo: '/login',
        pathMatch: 'full'
    },
    {
        path: 'home',
        loadChildren: () => import('./home/home.module').then(m => m.HomePageModule),
        canActivate: [ RouteGuardService ]
    },
    {
        path: 'login',
        loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule),
        canActivate: [ RouteGuardService ]
    },
    {
        path: 'building/:buildingId',
        loadChildren: () => import('./building-info/building-info.module').then(m => m.BuildingInfoPageModule),
        canActivate: [ RouteGuardService ]
    },
    {
        path: 'building/:buildingId/verified-owners',
        loadChildren: () => import('./building-owners/building-owners.module').then(m => m.BuildingOwnersPageModule),
        canActivate: [ RouteGuardService ]
    },
    {
        path: 'building/:buildingId/new-proposal',
        loadChildren: () => import('./new-proposal/new-proposal.module').then(m => m.NewProposalPageModule),
        canActivate: [ RouteGuardService ]
    },
    {
        path: 'settings',
        loadChildren: () => import('./settings/settings.module').then(m => m.SettingsPageModule),
        canActivate: [ RouteGuardService ]
    },
    {
        path: 'scheduled-calls',
        loadChildren: () => import('./scheduled-calls/scheduled-calls.module').then(m => m.ScheduledCallsPageModule),
        canActivate: [ RouteGuardService ]
    },
    {
        path: 'building/:buildingId/new-scheduled-call',
        loadChildren: () => import('./new-scheduled-call/new-scheduled-call.module').then(m => m.NewScheduledCallPageModule),
        canActivate: [ RouteGuardService ]
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' }),
        TranslateModule,
        IonicModule,
        HeaderModule,
        CommonModule,
    ],
    exports: [ RouterModule ],
})
export class AppRoutingModule {
}
