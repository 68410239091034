import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { roleBasedHomePath } from './user';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class RouteGuardService implements CanActivate {
    constructor(
        private router: Router,
        private authService: AuthService
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (state.url === '/login') {
            if (this.authService.userRoles === undefined) {
                return true;
            }

            this.router.navigate([ roleBasedHomePath(this.authService.userRoles) ]);
            return;
        }

        if (this.authService.userRoles !== undefined) {
            return true;
        }

        this.router.navigate([ '/login' ], { queryParams: { returnUrl: state.url } });
        return undefined;
    }
}
