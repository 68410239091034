import * as Rollbar from 'rollbar';

import {
    Injectable,
    ErrorHandler,
    InjectionToken,
    Inject
} from '@angular/core';
import { environment } from '../../environments/environment';
import { AuthService } from './auth/auth.service';

const rollbarConfig: Rollbar.Configuration = {
    accessToken: '43bdae18d02d432cb0ee4e8449e37e9f',
    captureUncaught: true,
    captureUnhandledRejections: true,
    autoInstrument: true,
    reportLevel: 'info' as Rollbar.Level,
    environment: environment.rollbarEnvironment,
    payload: {
        client: {
            javascript: {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                code_version: environment.appVersion
            }
        }
    }
};

export type Logger = Pick<Rollbar, 'debug' | 'info' | 'warn' | 'error' | 'configure'>;
export const LOGGER_SERVICE = new InjectionToken<Logger>('logger-service');
/* eslint-disable no-console */
export const loggerFactory = (): Logger => {
    const rollbar = new Rollbar(rollbarConfig);
    return {
        debug: (...args: Rollbar.LogArgument[]): Rollbar.LogResult => {
            console.debug(...args);
            return rollbar.debug(...args);
        },
        error: (...args: Rollbar.LogArgument[]): Rollbar.LogResult => {
            console.error(...args);
            return rollbar.error(...args);
        },
        info: (...args: Rollbar.LogArgument[]): Rollbar.LogResult => {
            console.info(...args);
            return rollbar.info(...args);
        },
        warn: (...args: Rollbar.LogArgument[]): Rollbar.LogResult => {
            console.warn(...args);
            return rollbar.warn(...args);
        },
        configure: (options: Rollbar.Configuration): Rollbar => rollbar.configure(options)
    };
};

@Injectable({ providedIn: 'root' })
export class RollbarErrorHandler implements ErrorHandler {
    constructor(
        private authService: AuthService,
        @Inject(LOGGER_SERVICE) private rollbar: Rollbar,
    ) {
        const originalErrorLog = console.error;
        if (environment.rollbarEnvironment === 'dev') {
            this.rollbar.configure({ enabled: false });
        }
        if (authService.user) {
            this.configureUser();
        }
        authService.onLogin(this.configureUser);
        authService.onLogout(() => {
            this.rollbar.configure({
                enabled: environment.rollbarEnvironment !== 'dev',
                reportLevel: 'info',
                payload: {
                    person: undefined
                }
            });
        });
    }

    handleError(err: any): void {
        this.rollbar.error(err.originalError || err);
    }

    private configureUser = () => {
        this.rollbar.configure({
            payload: {
                person: {
                    id: this.authService.user.id,
                    username: this.authService.user.name,
                }
            }
        });
        const martaUserId = 'ba7966fc-f05d-48a2-bb49-e17a08a6a038';
        if (this.authService.user.id === martaUserId) {
            this.rollbar.configure({ reportLevel: 'debug' });
        }
    };
}
