import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { EMPTY, Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';


@Injectable({ providedIn: 'root' })
export class UnauthorizedRequestInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError(error => {
                if ([ 401, 403 ].includes(error.status)) {
                    this.authService.logout();
                    return EMPTY;
                }
                return throwError(error);
            })
        );
    }
}
