export type UserRole = 'flipper' | 'caller' | 'admin';

export interface User {
    name: string;
    id: string;
    city: string;
    email: string;
    language: string;
    flipperId?: string;
}

export const roleBasedHomePath = (roles: UserRole[]) => {
    switch (true) {
        case roles.includes('caller'):
            return '/home/callcenter';
        case roles.includes('admin'):
            return '/admin/stock';
        case roles.includes('flipper'):
            return '/home/favorites';
    }
};
