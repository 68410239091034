// Production
import { Environment } from './interface';

const testCommercialUserId = '7f3a99b5-6e4b-4ac1-8f6e-a63ebcd9a988';
const testCallerId = '27e2c4d8-ba75-476e-bbbd-70372b3dee9b';
const testFlipperId = '9fc8c18b-41f0-4be1-9bf8-deb1165965bf';

export const environment: Environment = {
    mixPanelToken: 'f39400012cf6b8786e3152394ba3a6e9',
    production: true,
    nodeApi: 'https://api.mkpremium.net/',
    rollbarEnvironment: 'prod',
    jwtAllowedDomains: [ 'api.mkpremium.net' ],
    appVersion: '52ea71d3e2ea68e8451a1004217e41c039e171d4',
    toggles: {
    }
};
