import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthService } from './services/auth/auth.service';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';

import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { JwtModule } from '@auth0/angular-jwt';
import { HeaderModule } from './components/header/header.module';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import localeEsExtra from '@angular/common/locales/extra/es';
import localePt from '@angular/common/locales/pt';
import localePtExtra from '@angular/common/locales/extra/pt';
import { environment } from '../environments/environment';
import { ServiceWorkerModule } from '@angular/service-worker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LOGGER_SERVICE, loggerFactory, RollbarErrorHandler } from './services/logger.service';
import { Subject } from 'rxjs';
import { MEETING_CREATED$ } from './services/injection-tokens.di';
import { UnauthorizedRequestInterceptor } from './services/http/unauthorized-request.interceptor';
import {
    INTRODUCTION_EMAIL_TRANSLATIONS,
    translations
} from './callcenter/services/introduction-email-composer/translations';
import { ErrorMessageInterceptorService } from './services/http/error-message-interceptor.service';
import { HammerConfig } from './hammer-config';


registerLocaleData(localeEs, localeEsExtra);
registerLocaleData(localePt, localePtExtra);


// AoT requires an exported function for factories
export const httpTranslationLoaderFactory = (http: HttpClient) => new TranslateHttpLoader(http);

@NgModule({
    declarations: [ AppComponent ],
    imports: [
        BrowserModule,
        IonicModule.forRoot({
            mode: 'ios'
        }),
        HammerModule,
        AppRoutingModule,
        HttpClientModule,
        ReactiveFormsModule,
        TranslateModule.forRoot({
            defaultLanguage: 'es',
            loader: {
                provide: TranslateLoader,
                useFactory: (httpTranslationLoaderFactory),
                deps: [ HttpClient ]
            }
        }),
        JwtModule.forRoot({
            config: {
                tokenGetter: () => localStorage.getItem('token'),
                allowedDomains: environment.jwtAllowedDomains,
            }
        }),
        HeaderModule,

        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),

        BrowserAnimationsModule,
    ],
    providers: [
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: HAMMER_GESTURE_CONFIG, useClass: HammerConfig },
        AuthService,
        TranslateService,
        { provide: LOCALE_ID, useValue: 'es_ES' },
        { provide: ErrorHandler, useClass: RollbarErrorHandler },
        { provide: LOGGER_SERVICE, useFactory: loggerFactory },
        { provide: MEETING_CREATED$, useValue: new Subject<{ buildingId: string }>() },
        { provide: HTTP_INTERCEPTORS, useClass: UnauthorizedRequestInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorMessageInterceptorService, multi: true },
        { provide: INTRODUCTION_EMAIL_TRANSLATIONS, useValue: translations },
    ],
    bootstrap: [ AppComponent ],
})
export class AppModule {
}
