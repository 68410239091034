import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';
import { LocalStorageService } from '../local-storage.service';
import * as moment from 'moment';
import { User, UserRole } from './user';

const permissionToRole: { [ key: string ]: UserRole } = {
    /*eslint-disable @typescript-eslint/naming-convention*/
    BUSINESS: 'flipper',
    ADMIN: 'admin',
    OPERATOR: 'caller'
    /*eslint-enable @typescript-eslint/naming-convention*/
};

type RemoteUserPermission = 'BUSINESS' | 'ADMIN' | 'OPERATOR';

@Injectable({ providedIn: 'root' })
export class AuthService {
    user?: User;
    user$ = new BehaviorSubject<User>(undefined);
    accessToken?: string;

    userRoles: UserRole[];

    private logoutSubject = new Subject<void>();
    private loginSubject = new Subject<void>();

    constructor(
        private httpClient: HttpClient,
        private jwtHelper: JwtHelperService,
        private router: Router,
        private localStorageService: LocalStorageService,
    ) {
        this.saveTokens(
            this.localStorageService.getItem('token'),
        );
        if (!this.accessToken) {
            this.logout();
            return;
        }

        this.setAuthInfoFromToken();
    }

    isUserAuthenticated() {
        return this.accessToken !== undefined && !this.jwtHelper.isTokenExpired(this.accessToken);
    }

    login(email, password) {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        const body = { username: email, password };
        const bodyString = JSON.stringify(body);

        return this.httpClient
            .post(environment.nodeApi + 'operators/login', bodyString, { headers })
            .pipe(
                map(
                    (response: { token: string }) => {
                        const accessToken = response.token;
                        if (accessToken) {
                            this.saveTokens(accessToken);
                            this.setAuthInfoFromToken();
                            this.loginSubject.next();
                        } else {
                            return throwError(new Error('Login failed'));
                        }
                    }
                )
            );
    }

    logout() {
        this.clearTokens();

        this.user = undefined;
        this.user$.next(undefined);
        this.logoutSubject.next();
        this.localStorageService.clear();
        this.userRoles = undefined;
        this.router.navigate([ '/login' ]).catch(console.error);
    }

    onLogin(cb: () => void) {
        this.loginSubject.subscribe(cb);
    }

    onLogout(cb: () => void) {
        this.logoutSubject.subscribe(cb);
    }

    userId() {
        return this.user.id;
    }

    private eraseToken() {
        this.localStorageService.removeItem('token');
    }

    private saveTokens(accessToken) {
        if (!accessToken) {
            return;
        }
        this.localStorageService.setItem('token', accessToken);

        const decodedToken = this.jwtHelper.decodeToken(accessToken);
        const expires = new Date(decodedToken.exp * 1000);
        if (moment(expires).isBefore(moment())) {
            return;
        }

        this.accessToken = accessToken;
    }

    private clearTokens() {
        this.eraseToken();
        this.accessToken = undefined;
    }

    private setAuthInfoFromToken() {
        if (!this.accessToken) {
            return;
        }

        const decodedToken: {
            id: string;
            permissions: (RemoteUserPermission)[];
            operator: {
                name: string;
                city: string;
                email: string;
                language: string;
            };
            flipperId?: string;
        } = this.jwtHelper.decodeToken(this.accessToken);
        if (!(decodedToken && decodedToken.operator)) {
            return;
        }

        this.userRoles = decodedToken.permissions.map(permission => permissionToRole[ permission ]);

        this.user = {
            id: decodedToken.id,
            flipperId: decodedToken.flipperId,
            ...decodedToken.operator,
        };
        this.user$.next(this.user);
    }
}
