import { InjectionToken } from '@angular/core';

export const INTRODUCTION_EMAIL_TRANSLATIONS = new InjectionToken<IntroductionEmailTranslations>('INTRODUCTION_EMAIL_TRANSLATIONS');

export interface IntroductionEmailTranslations {
    pt: { subject: string; body: string };
    cat: { subject: string; body: string };
    es: { subject: string; body: string };
}

export const translations: IntroductionEmailTranslations = {
    es: {
        subject: 'Carta de presentación',
        // eslint-disable-next-line max-len
        body: `Buenas tardes,<br/><br/>Según conversación mantenida con usted, le remitimos nuestros datos así como <a href="https://app.mkpremium.net/static/introduction_ES.pdf">nuestra carta de presentación.</a><br/><br/>Somos una Patrimonial Familiar, que nuestra principal actividad es la compra de activos inmobiliarios, con la finalidad de incorporar valor y mejorar su estado. Contamos con varios edificios en nuestra cartera, especializada en el alquiler de sus pisos.<br/><br/>Somos compradores directos y no dependemos de la financiación externa, tenemos nuestro propio fondo de inversiones. Esto agiliza mucho nuestra decisión de compra que en 24h - 48h si ambas partes están de acuerdo, podemos formalizar la compra.<br/><br/>Estamos interesados en la finca de su propiedad y nos gustaría visitarla y poder mantener una reunión con usted.<br/><br/>En espera de sus noticias, reciba un cordial saludo.<br/><br/>%%caller-first-name%% %%caller-last-name%%<br/>Departamento de Valoración<br/>Tel: 608188630`
    },
    pt: {
        subject: 'Carta de apresentação',
        // eslint-disable-next-line max-len
        body: `Boa tarde,<br/><br/>Após conversa telefónica, venho por este meio enviar-lhe os dados da nossa empresa, assim como a <a href="https://app.mkpremium.net/static/introduction_ES.pdf">nossa carta de apresentação.</a><br/><br/>A MK Premium é uma empresa familiar, cuja principal atividade assenta na compra de ativos imobiliários, com a finalidade de valorizar e melhorar o seu estado. Como tal, dispomos de uma carteira de clientes caracterizada pelo arrendamento de apartamentos.<br/><br/>Somos compradores diretos e não dependemos de financiamentos externos, na medida em que detemos um fundo de investimento próprio, utilizando assim os nossos capitais. Esta situação facilita bastante as nossas compras, já que se ambas as partes estiverem de acordo, em 24h a 48h podemos formalizar a compra de um edifício.<br/><br/>Em suma, informamos temos interesse no seu prédio e por isso, gostaríamos de visitá-lo e poder marcar uma reunião com V/Exa(s).<br/><br/>Fico a aguardar com expectativa o seu contacto. Melhores cumprimentos <br/><br/>%%caller-first-name%% %%caller-last-name%%<br/>Departamento de Valorização`
    },
    cat: {
        subject: 'Carta de presentació',
        // eslint-disable-next-line max-len
        body: `Bona tarda,<br/><br/>A partir de la conversa mantinguda amb vostè, li remetem les nostres dades, a més de la <a href="https://app.mkpremium.net/static/introduction_ES.pdf">nostra carta de presentació.</a><br/><br/>Som una Patrimonial Familiar i la nostra activitat principal és l’adquisició d’actius immobiliaris, amb la finalitat d’afegir valor i millorar el seu estat. Tenim diversos edificis a la nostra cartera i ens especialitzem en el lloguer dels seus pisos.<br/><br/>Som compradors directes i no depenem del finançament extern, ja que tenim el nostre propi fons d’inversions. Això agilitza molt la nostra decisió de compra que en 24h - 48h, si les dues parts estan d’acord, podem formalitzar la compra.<br/><br/>Estem interessats en la finca de la seva propietat i voldríem visitar-la i poder mantenir una reunió amb vostè.<br/><br/>En espera de les seves notícies, rebi una cordial salutació. <br/><br/>%%caller-first-name%% %%caller-last-name%%<br/>Departament de Valoració<br/>Tel: 608188630`
    },
};
